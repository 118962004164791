import { React } from 'react';
import HomePage from './pages/HomePage';
import GenerateSSLPage from './pages/GenerateSSLPage';
import { Switch, Route } from 'react-router-dom';
import ContactUsPage from './pages/ContactUsPage';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/contact-us" component={ContactUsPage} />
            <Route exact path="/create-free-ssl" component={GenerateSSLPage} />
        </Switch>
    );
}

export default Routes;
