import { React } from 'react';
import { Button,List, Typography, Table } from 'antd';
import { useSelector } from 'react-redux';
import { downloadCustomFile } from '../../utils/GeneralUtils';

const AcmeVerificationComponent = () => {
    const verificationData = useSelector(state=>state.SSLGenerationForms.challenge_data);
    return (
        <>
            {(verificationData.challenge_type === 'http') ? <HtmlVerificationComponent verificationData={verificationData} />: <DnsVerificationComponent verificationData={verificationData} />}
        </>
    );
}

const HtmlVerificationComponent = (props) => {

    const verificationData = props.verificationData;

    return (
        <div className="container px-0 my-3">
            <div className="row mb-2">
                <div className="col-lg-12">
                    <Typography.Title level={4}>1. Download below file(s):</Typography.Title>
                </div>
            </div>
            <div className="row mb-4">
                {verificationData.data.map((item, index)=>{
                    return (<div className="col-lg-2 pb-2">
                        <Button type="primary" onClick={()=>downloadCustomFile(item.filename, item.content)}>{`Download File #${index+1}`}</Button>
                    </div>);
                })}
            </div>
            <div className="row mb-4">
                <div className="col-lg-12">
                    <Typography.Title level={4}>2. Create a folder ".well-known" in the root folder of your domain. And inside the ".well-known" create another folder "acme-challenge". Then upload the above file(s) inside the acme-challenge folder.</Typography.Title>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-lg-12">
                    <Typography.Title level={4}>3. Click on the below link(s) and check that it opens a page with random characters on your domain. Like this:</Typography.Title>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-lg-12">
                    <List
                        size="small"
                        dataSource={verificationData.data}
                        renderItem={item => <List.Item>{<a rel="noreferrer" target="_blank" href={`http://${item.domain}/.well-known/acme-challenge/${item.filename}`}>{`http://${item.domain}/.well-known/acme-challenge/${item.filename}`}</a>}</List.Item>}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <Typography.Title level={4}>4. Click on the Next button and Let's Encrypt will verify that you own the domain and issue the SSL Certificate (This might take few minutes)</Typography.Title>
                </div>
            </div>
        </div>
    );
}

const DnsVerificationComponent = (props) => {

    const verificationData = props.verificationData;

    const dataSource = verificationData.data;

      const columns = [
          {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
          },
        {
          title: 'Domain TXT record',
          dataIndex: 'txt_record_name',
          key: 'name',
        },
        {
          title: 'Value',
          dataIndex: 'txt_record_value',
          key: 'value',
        }
      ];

    return (
        <div className="container px-0 my-3">
            <div className="row mb-2">
                <div className="col-lg-12">
                    <Typography.Title level={4}>1. Login to your domain host (or wherever service that is "in control" of your domain).</Typography.Title>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-lg-12">
                    <Typography.Title level={4}>2. Go to the DNS record settings and create a new TXT record.</Typography.Title>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-lg-12">
                    <Typography.Title level={4}>3. In the Name/Host/Alias field, enter the domain TXT record from below table for example: "_acme-challenge".</Typography.Title>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-lg-12">
                    <Typography.Title level={4}>4. In the Value/Answer field enter the verfication code from below table.</Typography.Title>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-lg-12">
                    <Typography.Title level={4}>5. Wait for few minutes for the TXT record to propagate. You can check if it worked by clicking on the "Check DNS" button. If you have multiple entries, make sure all of them are ok.</Typography.Title>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} />
        </div>
    );
}

export default AcmeVerificationComponent;
