import { UPDATE_SSL_DETAILS_FORM_DATA, UPDATE_SSL_CURRENT_STEP, UPDATE_LOADER, UPDATE_CHALLENGE_DATA, UPDATE_CERTIFICATE_DATA, RESET_SSL_FORM } from '../constants/SSLGenerationFormsConstants';

const initialState = {
    loading: false,
    currentStep: 0,
    accessToken: null,
    details: {
        domains: null,
        email: null,
        challenge_type: 'http',
        accept_tos: []
    },
    challenge_data: {
        challenge_type: 'http',
        data: []
    },
    certificate_data: {
        certificate: null,
        private_key: null
    }
}

const SSLGenerationFormsReducer = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_SSL_DETAILS_FORM_DATA:
            return {
                ...state,
                details: action.payload
            }
        case UPDATE_SSL_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.payload
            }
        case UPDATE_LOADER:
            return {
                ...state,
                loading: action.payload
            }
        case UPDATE_CHALLENGE_DATA:
            return {
                ...state,
                challenge_data: {...action.payload.challenge_data},
                accessToken: action.payload.access_token
            }
        case UPDATE_CERTIFICATE_DATA:
            return {
                ...state,
                certificate_data: {...action.payload}
            }
        case RESET_SSL_FORM:
            return {
                ...initialState
            }
        default:
            return state;
    }
}

export default SSLGenerationFormsReducer;
