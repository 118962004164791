import { UPDATE_SSL_DETAILS_FORM_DATA, UPDATE_SSL_CURRENT_STEP, UPDATE_LOADER, UPDATE_CHALLENGE_DATA, UPDATE_CERTIFICATE_DATA, RESET_SSL_FORM } from '../constants/SSLGenerationFormsConstants';
import { message } from 'antd';
import { generateChallenge, selfTestDomainVerification, acmeDomainVerification, generateCertificate } from '../../services/sslService';

export const updateDetailsFormData = (formData) => {
    return {
        type: UPDATE_SSL_DETAILS_FORM_DATA,
        payload: formData
    }
}

export const updateCurrentStep = (currentStep) => {
    return {
        type: UPDATE_SSL_CURRENT_STEP,
        payload: currentStep
    }
}

export const resetSSLForm = () => {
    return {
        type: RESET_SSL_FORM,
        payload: {}
    }
}

export const submitDomainDetails = () => {
    return async function(dispatch, getState) {

        dispatch({'type':UPDATE_LOADER, payload:true});

        let formdata = getState().SSLGenerationForms.details;

        let response = await generateChallenge(formdata);
        if(response.status)
        {
            dispatch({'type':UPDATE_LOADER, payload:false});
            dispatch(updateCurrentStep(1));
            dispatch({type: UPDATE_CHALLENGE_DATA, payload: {
                challenge_data: {challenge_type: formdata.challenge_type, data: response.data.challenge},
                access_token: response.data.token
            }});
        }
        else
        {
            dispatch({'type':UPDATE_LOADER, payload:false});
            message.error(response.data.message);
        }
    };
}

export const submitVerifyDomain = () => {
    return async function(dispatch, getState) {
        dispatch({'type':UPDATE_LOADER, payload:true});
        let accessToken = getState().SSLGenerationForms.accessToken;
        let response = await selfTestDomainVerification(accessToken);
        if(response.status)
        {
            let finalResponse = await acmeDomainVerification(accessToken);
            if(finalResponse.status)
            {
                let cerificateResp = await generateCertificate(accessToken);
                if(cerificateResp.status)
                {
                    dispatch({'type':UPDATE_LOADER, payload:false});
                    dispatch(updateCurrentStep(2));
                    dispatch({type: UPDATE_CERTIFICATE_DATA, payload: {
                        certificate: cerificateResp.data.certificate.certificate_cert,
                        private_key: cerificateResp.data.certificate.private_key
                    }});
                }
                else
                {
                    dispatch({'type':UPDATE_LOADER, payload:false});
                    message.error(cerificateResp.data.message);
                }
            }
            else
            {
                dispatch({'type':UPDATE_LOADER, payload:false});
                message.error(finalResponse.data.message);
            }
        }
        else
        {
            dispatch({'type':UPDATE_LOADER, payload:false});
            message.error(response.data.message);
        }
    };
}
