import { React, useEffect, useState } from 'react';
import { Menu, PageHeader, Divider } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './header.css';

const HeaderComponent = () => {

    const location = useLocation();

    const [state, setState] = useState({
        current: location.pathname,
    });

    useEffect(()=>{
        setState({
            current: location.pathname
        });
    },[location.pathname]);

    const current = state.current;

    const getNavigation = () => {
        return (
            <Menu selectedKeys={[current]} mode="horizontal">
                <Menu.Item key="/">
                    <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="/create-free-ssl">
                    <Link to="/create-free-ssl">Create Free SSL</Link>
                </Menu.Item>
                <Menu.Item key="/contact-us">
                    <Link to="/contact-us">Contact Us</Link>
                </Menu.Item>
            </Menu>
        );
    }

    return (
        <div className="lssl-head-nav">
            <PageHeader
                title={<Link style={{color:'rgba(0, 0, 0, 0.85)'}} to="/">Launch SSL</Link>}
                extra={[
                    getNavigation()
                ]}
            />
            <Divider />
        </div>
    );
}

export default HeaderComponent;
