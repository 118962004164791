import { React, useEffect } from 'react';
import { Steps, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import DomainDetailsComponent from '../components/ssl_generator/DomainDetailsComponent';
import AcmeVerificationComponent from '../components/ssl_generator/AcmeVerificationComponent';
import GenerateCertificateComponent from '../components/ssl_generator/GenerateCertificateComponent';
import { updateCurrentStep, submitDomainDetails, submitVerifyDomain, resetSSLForm } from '../store/actions/SSLGenerationFormsActions';

const GenerateSSLPage = () => {

    const { Step } = Steps;

    const dispatch = useDispatch();

    const sslFormState = useSelector(state=>state.SSLGenerationForms);

    const sslFormLoading = sslFormState.loading;
    const current = sslFormState.currentStep;

    const next = async () => {
        if(current === 0)
        {
            await submitDomainDetailsForm();
        }
        if(current === 1)
        {
            await submitVerifyDomainForm();
        }
    };

    const submitDomainDetailsForm = async () => {
        await dispatch(submitDomainDetails());
    }

    const submitVerifyDomainForm = async () => {
        await dispatch(submitVerifyDomain());
    }

    const prev = () => {
        dispatch(updateCurrentStep(current-1));
    };

    useEffect(() => {
        document.title = `Generate SSL Certificate | ${process.env.REACT_APP_APPLICATION_NAME}`;
    });

    const steps = [
        {
            title: 'Details',
            description: 'Domains and email',
            content: <DomainDetailsComponent />,
        },
        {
            title: 'Verification',
            description: 'Acme domain verification',
            content: <AcmeVerificationComponent />,
        },
        {
            title: 'Certificates',
            description: 'Download SSL certificates',
            content: <GenerateCertificateComponent />,
        }
    ];

    return (
        <div className="lssl-about-us lssl-main-container">
            <section className="border-bottom">
                <div className="container my-5">
                    <Steps responsive={true} size="small" current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} description={item.description} />
                        ))}
                    </Steps>
                    <div className="steps-content">{steps[current].content}</div>
                    <div className="steps-action">
                        {current < 2 && (
                            <Button loading={sslFormLoading} type="primary" onClick={() => next()}>
                                Next
                            </Button>
                        )}
                        {(current > 0 && current < 2) && (
                            <>
                                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    Previous
                                </Button>
                                <Button style={{ margin: '0 8px' }} onClick={() => {dispatch(resetSSLForm())}}>
                                    Reset
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default GenerateSSLPage;
