import { Divider } from 'antd';
import { React } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const FooterComponent = () => {

    return (
        <div className="lssl-footer">
            <Divider />
            <footer>
                <div className="footerLinks">
                    <ul>
                        <li key="home"><Link to="/">Home | </Link></li>
                        <li key="create-free-ssl"><Link to="/create-free-ssl">Create Free SSL | </Link></li>
                        {/* <li key="privacy-policy"><Link to="/privacy-policy">Privacy Policy | </Link></li> */}
                        <li key="contact-us"><Link to="/contact-us">Contact Us </Link></li>
                    </ul>
                </div>
                <div className="copyright">
                    {/* <p>Made by Apcomp Infotech LLP © 2021</p> */}
                </div>
            </footer>
        </div>
    );
}

export default FooterComponent;
