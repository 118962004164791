import { React } from 'react';
import { Row, Col, Form, Input, Radio, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { updateDetailsFormData } from '../../store/actions/SSLGenerationFormsActions';

const DomainDetailsComponent = () => {

    const formData = useSelector(state => state.SSLGenerationForms.details);
    const dispatch = useDispatch();

    const layout = {
        labelAlign: 'left',
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 24,
        },
    }

    const updateFormData = (changedValues) => {
        dispatch(updateDetailsFormData({
            ...formData,
            ...changedValues
        }));
    }

    return (
        <div className="container px-0 my-3">
            <Form onValuesChange={updateFormData} initialValues={formData} {...layout} name="nest-messages">
                    <Form.Item
                        name="domains"
                        label="Domains"
                    >
                    <Input placeholder="yourdomain.com, www.yourdomain.com" />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                >
                    <Input placeholder="emailaddress@gmail.com" />
                </Form.Item>
                <Form.Item name="challenge_type" label="Challenge type">
                    <Radio.Group>
                        <Radio value="http">HTTP verification</Radio>
                        <Radio value="dns">DNS verification</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="accept_tos" label="Accept Terms">
                    <Checkbox.Group>
                        <Row>
                            <Col span={16}>
                                <Checkbox value={1} style={{ lineHeight: '32px' }}>
                                    <Link target="_blank" to="/privacy-policy">Accept LaunchSSL TOS</Link>
                                </Checkbox>
                            </Col>
                            <Col span={16}>
                                <Checkbox value={2} style={{ lineHeight: '32px' }}>
                                    <a rel="noreferrer" href="https://letsencrypt.org/documents/LE-SA-v1.2-November-15-2017.pdf" target="_blank">Accept Let's Encrypt SA (PDF)</a>
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </div>
    );
}

export default DomainDetailsComponent;
