import { React, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateDetailsFormData } from '../store/actions/SSLGenerationFormsActions';
import { useHistory } from 'react-router-dom';

const HomePage = () => {

    const [domains, setDomains] = useState('');

    const dispatch = useDispatch();

    const history = useHistory();

    const submitDomains = (e) => {
        dispatch(updateDetailsFormData({
            domains: domains,
            email: null,
            challenge_type: 'http',
            accept_tos: []
        }));
        history.push('/create-free-ssl');
    }

    useEffect(() => {
        document.title = `Home | ${process.env.REACT_APP_APPLICATION_NAME}`;
    });

    return (
        <div className="lssl-home lssl-main-container">
            <header className="bg-dark py-5">
                <div className="container px-5">
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center my-5">
                                <h1 className="display-5 fw-bolder text-white mb-2">Free SSL Protection for any site</h1>
                                <p className="lead text-white-50 mb-4">Quickly create a Free Let's Encrypt SSL Certificate valid for 90 days. Enter your domains below.</p>
                                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center">
                                    <input className="form-control" onChange={(e)=>{setDomains(e.target.value)}} id="name" type="text" placeholder="yourdomain.com, www.yourdomain.com"/>
                                    <button type="button" onClick={(e)=>{submitDomains(e)}} className="btn btn-primary btn-lg px-4 me-sm-3">Create Free SSL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="py-5 border-bottom" id="features">
                <div className="container px-5 my-5">
                    <div className="row gx-5">
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-collection"></i></div>
                            <h2 className="h4 fw-bolder">Unlimited SSL Certificates</h2>
                            <p>Easily create and renew unlimited 90 day SSL certificates within few minutes.</p>
                        </div>
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-building"></i></div>
                            <h2 className="h4 fw-bolder">Free Forever</h2>
                            <p>We are free forever. Generate unlimited free 90 day SSL certificates and never pay again for SSL certificates.</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-toggles2"></i></div>
                            <h2 className="h4 fw-bolder">SSL Benefits</h2>
                            <p>Protect user's information, gain trust and improve Search Engine Ranking of your website. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light py-5 border-bottom">
                <div className="container px-5 my-5 px-5">
                    <div className="text-center mb-5">
                        <h2 className="fw-bolder">User Testimonials</h2>
                        <p className="lead mb-0">Our users love our service</p>
                    </div>
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-6">
                            <div className="card mb-4">
                                <div className="card-body p-4">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0"><i className="bi bi-chat-right-quote-fill text-primary fs-1"></i></div>
                                        <div className="ms-4">
                                            <p className="mb-1">Thank you for putting together such a great product. We love what you are developing, and we will be recommending you to others!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0"><i className="bi bi-chat-right-quote-fill text-primary fs-1"></i></div>
                                        <div className="ms-4">
                                            <p className="mb-1">This site is a huge help for us in putting things together for our website and securing it!</p>
                                            {/* <div className="small text-muted">- Name, Country</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HomePage;
