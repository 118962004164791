import { React, useEffect, useState } from 'react';
import { submitContactUs } from '../services/contactUsService';
import { message } from 'antd';

const ContactUsPage = () => {

    useEffect(() => {
        document.title = `Contact Us | ${process.env.REACT_APP_APPLICATION_NAME}`;
    });

    let [contactFormData, setContactFormData] = useState({
        loading: false,
        name: '',
        email: '',
        message: ''
    });

    const updateFieldHandler = (changedField) => {
        setContactFormData({
            ...contactFormData,
            ...changedField
        });
    }

    const submitContactFormHandler = async () => {
        setContactFormData({
            ...contactFormData,
            loading: true
        });
        let contactUsResponse = await submitContactUs(contactFormData);
        setContactFormData({
            ...contactFormData,
            loading: false
        });
        if(contactUsResponse.status)
        {
            setContactFormData({
                ...contactFormData,
                name: '',
                email: '',
                message: ''
            });
            message.success(contactUsResponse.data.message);
        }
        else
        {
            message.error(contactUsResponse.data.message);
        }
    }

    return (
        <div className="lssl-home lssl-main-container">
            <section className="bg-light py-5">
                <div className="container px-5 my-5 px-5">
                    <div className="text-center mb-5">
                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-envelope"></i></div>
                        <h2 className="fw-bolder">Get in touch</h2>
                        <p className="lead mb-0">We'd love to hear from you</p>
                    </div>
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-6">
                            <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                                <div className="form-floating mb-3">
                                    <input className="form-control" id="name" value={contactFormData.name} type="text" onChange={(e)=>updateFieldHandler({name: e.target.value})} placeholder="Enter your name..." />
                                    <label htmlFor="name">Full name</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input className="form-control" id="email" value={contactFormData.email} type="email" onChange={(e)=>updateFieldHandler({email: e.target.value})} placeholder="name@example.com" />
                                    <label htmlFor="email">Email address</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea className="form-control" id="message" type="text" placeholder="Enter your message here..." onChange={(e)=>updateFieldHandler({message: e.target.value})} value={contactFormData.message}></textarea>
                                    <label htmlFor="message">Message</label>
                                </div>
                                <div className="d-grid"><button className={"btn btn-primary btn-lg " + (contactFormData.loading ? 'disabled' : '')} id="submitButton" onClick={()=>{submitContactFormHandler()}} type="button">{(contactFormData.loading) ? 'Please wait...' : 'Submit'}</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ContactUsPage;
