import { React } from 'react';
import { Typography, Form, Input, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { downloadCustomFile } from '../../utils/GeneralUtils';
import { resetSSLForm } from '../../store/actions/SSLGenerationFormsActions';

const GenerateCertificateComponent = () => {

    const certificateData = useSelector(state=>state.SSLGenerationForms.certificate_data);

    const dispatch = useDispatch();

    return (
        <div className="container px-0 my-3">
            <div className="row mb-4">
                <div className="col-lg-12">
                    <Typography.Title level={4}>1. Download Certificate:</Typography.Title>
                    <Form.Item>
                        <Input.TextArea rows={5} value={certificateData.certificate} />
                    </Form.Item>
                    <Button type="primary" onClick={()=>downloadCustomFile('certificate.txt', certificateData.certificate)}>
                        Download
                    </Button>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-lg-12">
                    <Typography.Title level={4}>2. Download Private Key:</Typography.Title>
                    <Form.Item>
                        <Input.TextArea rows={5} value={certificateData.private_key} />
                    </Form.Item>
                    <div className="row col-lg-6">
                        <div className="col-lg-2 mr-2 mb-2">
                            <Button type="primary" onClick={()=>downloadCustomFile('private_key.txt', certificateData.private_key)}>
                                Download
                            </Button>
                        </div>
                        <div className="col-lg-2 mr-2">
                            <Button onClick={() => {dispatch(resetSSLForm())}}>
                                Create New Certificate
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GenerateCertificateComponent;
