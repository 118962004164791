import axios from 'axios';

export const generateChallenge = async (formdata) => {
    try{
        let response = await axios.post(process.env.REACT_APP_API_BASE_URL+'/api/generate-challenge', {
            domain_names: (formdata.domains) ? formdata.domains.split(',').map(function(item) {
                return item.trim();
              }) : [],
            email: formdata.email,
            tos_accept: (formdata.accept_tos.length === 2 && formdata.accept_tos.includes(1) && formdata.accept_tos.includes(2)) ? 1 : 0,
            challenge_type: formdata.challenge_type
        });
        return {
            status: true,
            data: response.data
        }
    }
    catch(err)
    {
        return {
            status: false,
            data: err.response.data
        }
    }
}

export const selfTestDomainVerification = async (token) => {
    try{
        let config = {
            headers: {
              Token: token,
            }
        }
        let response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/self-test', config);
        if(response.data.status)
        {
            return {
                status: true,
                data: response.data
            }
        }
        else
        {
            return {
                status: false,
                data: response.data
            }
        }
    }
    catch(err)
    {
        return {
            status: false,
            data: err.response.data
        }
    }
}

export const acmeDomainVerification = async (token) => {
    try{
        let config = {
            headers: {
              Token: token,
            }
        }
        let response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/acme-verification', config);
        if(response.data.status)
        {
            return {
                status: true,
                data: response.data
            }
        }
        else
        {
            return {
                status: false,
                data: response.data
            }
        }
    }
    catch(err)
    {
        return {
            status: false,
            data: err.response.data
        }
    }
}

export const generateCertificate = async (token) => {
    try{
        let config = {
            headers: {
              Token: token,
            }
        }
        let response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/generate-cert', config);
        if(response.data.status)
        {
            return {
                status: true,
                data: response.data
            }
        }
        else
        {
            return {
                status: false,
                data: response.data
            }
        }
    }
    catch(err)
    {
        return {
            status: false,
            data: err.response.data
        }
    }
}
