import axios from 'axios';

export const submitContactUs = async (formdata) => {
    try{
        let response = await axios.post(process.env.REACT_APP_API_BASE_URL+'/api/contact-us', formdata);
        return {
            status: true,
            data: response.data
        }
    }
    catch(err)
    {
        return {
            status: false,
            data: err.response.data
        }
    }
}
