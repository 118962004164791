import HeaderComponent from './components/header/HeaderComponent';
import FooterComponent from './components/footer/FooterComponent';
import Routes from './Routes';
import 'antd/dist/antd.css';

function App() {
  return (
    <div className="App">
        <HeaderComponent />
        <Routes />
        <FooterComponent />
    </div>
  );
}

export default App;
